import moment from 'moment'
import * as R from 'ramda'
import Cookies from "js-cookie"
import getConfig from 'next/config'
const {
  publicRuntimeConfig
} = getConfig()
/////////Starbucks//////

export function isAnonymUser(state) {
  if (state.user && state.user.user) {
    return false
  }
  return true
}

export function getUserFullName(state) {
  if (state.user.user) {
    return state.user.user.firstName + " " + state.user.user.lastName
  }
  return "-"
}

export function getUserEmail(state) {
  if (state.user.user) {
    return state.user.user.email
  }
  return "-"
}

export function getUserMasterPassPhoneNumber(state) {
  //return R.path(["user", "user", "masterpassPhoneNumber"], state)
  //return R.path(["user", "user", "phoneNumber"], state)
  if (state.user && state.user.user && state.user.user.masterpassPhoneNumber) {
    return state.user.user.masterpassPhoneNumber
  } else if (state.user && state.user.user && state.user.user.phoneNumber) {
    return state.user.user.phoneNumber
  }
  return ""
}

export function getUserFirstName(state) {
  return R.path(["user", "user", "firstName"], state)
}

export function getUserPreferredName(state) {
  if (state.user.user) {
    return state.user.user.preferredName ? state.user.user.preferredName : state.user.user.firstName
  }
  return ""
}

export function getCategoryById(state, categoryId) {
  let subCategories = [...state.catalog.subCategories]
  let category = {
    name: ""
  }
  if (state.catalog.subCategories) {
    category = subCategories.find(c => c.id == categoryId)
  }
  return category
}

export function getProductsOfCategories(state, categoryId) {
  let products = []
  if (categoryId && state.catalog.products) {
    products = state.catalog.products.filter(p => {
      return p.categories.indexOf(categoryId) >= 0
    })
  }
  return products
}

export function getUserCardsList(state) {
  //let cardslist = []
  if (state.user && state.user.user && state.user.user.cards) {
    return state.user.user.cards
  }
  return []
}

export function userHasSbCard(state) {
  if (state.user && state.user.user && state.user.user.cards && state.user.user.cards.length >= 1) {
    return true
  }
  return false
}

export function getMasterpassCards(state) {
  if (state.user && state.user.masterpassCardsList) {
    return state.user.masterpassCardsList
  }
  return []
}

export function getUserOrders(state) {
  let orders = []
  if (state.user && state.user.orders) {
    return state.user.orders
  }
  return orders
}

export function getStoreFilterKeys(state) {
  return state.catalog.storeFilterKeys ? state.catalog.storeFilterKeys : []
}

export function getFilteredStores(state) {
  let stores = []
  if (state.catalog && state.catalog.stores) {
    stores = state.catalog.stores
      .filter(s => s.name?.tr_TR.toLowerCase().indexOf(state.catalog.storeSearchTerm.toLowerCase()) >= 0)
      .filter(s => {
        if (state.catalog.storeFilters.length == 0) {
          return true
        } else {
          let allFiltersOk = true
          state.catalog.storeFilters.forEach(f => {
            if (s[f] != true) {
              allFiltersOk = false
            }
          })
          return allFiltersOk
        }
      })
  }
  return stores.filter(s => s.type == "store")
}

export function getFavoriteStores(state) {
  if (state.user && state.user.user && state.user.user.favoriteStores) {
    return state.user.user.favoriteStores
  }
  return []
}

export function getStoreListFromFavorites(state) {
  let favoriteStores = []
  if (state.catalog && state.catalog.stores && state.user && state.user.user && state.user.user.favoriteStores) {
    state.user.user.favoriteStores.forEach(storeId => {
      if (state.catalog.stores.find(str => str.id == storeId)) {
        favoriteStores.push(state.catalog.stores.find(str => str.id == storeId))
      }
    })
  }
  return favoriteStores
}

export function getFavoriteProducts(state) {
  if (state.user && state.user.user && state.user.user.favoriteProducts) {
    return state.user.user.favoriteProducts
  }
  return []
}

export function getFavoriteProductsList(state) {
  if (state.user && state.user.user && state.user.user.favoriteProducts && state.catalog && state.catalog.products) {
    return state.catalog.products.filter(p => {
      return state.user.user.favoriteProducts.indexOf(p.id) >= 0
    })
  }
  return []
}

export function getFeaturedProducts(state) {
  if (state.catalog && state.catalog.products) {
    return state.catalog.products.filter(p => p.featured == true)
  }
  return []
}

export function isAutoreloadActive(state) {
  if (state.user && state.user.user && state.user.user.autoReloadCardInfo && state.user.user.autoReloadCardInfo.cardName) {
    return true
  }
  return false
}

export function getFirestoreAutoreloadData(state) {
  if (state.user && state.user.user && state.user.user.autoReloadCardInfo && state.user.user.autoReloadCardInfo.cardName) {
    return state.user.user.autoReloadCardInfo
  }
  return null
}

export function getAccountHistoryActivities(state) {
  if (state.user && state.user.activities) {
    return state.user.activities.filter(a => {
      return (a.type == "order" || a.type == "payment" || a.type == "star-update")
    })
  }
  return []
}

export function getUserStarsBalance(state) {
  if (state.user && state.user.user && state.user.user.stars) {
    return (state.user.user.stars.starCount)
  }
  return 0
}

export function getStarsEarnedUntilReward(state) {
  if (state.user && state.user.user && state.user.user.stars) {
    return state.user.user.stars.starsEarnedUntilReward
  }
  return 0
}

export function getUserRewardsCount(state) {
  if (state.user && state.user.user && state.user.user.stars) {
    return state.user.user.stars.rewardCount
  }
  return 0
}

export function getStarsRequiredForReward(state) {
  if (state.user && state.user.user && state.user.user.stars) {
    return state.user.user.stars.starsRequiredForReward
  }
  return 0
}

export function getLifetimeEarnedStars(state) {
  if (state.user && state.user.user && state.user.user.stars) {
    return state.user.user.stars.lifetimeEarnedStars
  }
  return 0
}

function getSystemLocale() {
  if (Cookies.get("systemLocale") == "tr") {
    Cookies.set("preferredLocale", "tr")
    return "tr"
  } else {
    Cookies.set("preferredLocale", "en")
    return "en"
  }
}

export function getLocale(state) {
  //once fb yoksa cookie yoksa tr
  // if (state && state.user && state.user.user && state.user.user.preferredLocale) {
  //   if (state.user.user.preferredLocale == "en") {
  //     return "en"
  //   } else return "tr"
  // } 
  if (Cookies.get("preferredLocale")) {
    if (Cookies.get("preferredLocale") == "en") {
      return "en"
    } else return "tr"
  }
  return "tr"
}

export function getLocaleTrEn(state) {
  //once fb yoksa cookie yoksa tr
  if (state.user && state.user.user && state.user.user.preferredLocale) {
    if (state.user.user.preferredLocale == "en") {
      return "En"
    } else return "Tr"
  } else if (Cookies.get("preferredLocale")) {
    if (Cookies.get("preferredLocale") == "en") {
      return "En"
    } else return "Tr"
  }
  return "Tr"
}

export function getMpSmsLanguage(state) {
  if (getLocale(state) == "en") {
    return "eng"
  }
  return "tur"
}

export function userHasPinCode(state) {
  if (state.user && state.user.user && state.user.user.pinExists) {
    return true
  }
  return false
}

export function isPinCodeActive(state) {
  if (state.user && state.user.user && state.user.user.pinStatus) {
    return true
  }
  return false
}

export function getChallengeMessage(state) {
  let local = getLocale(state)
  if (state.auth && state.auth.challenge && state.auth.challenge.challengeMessage) {
    return state.auth.challenge.challengeMessage[local]
  }
  return ""
}

export function hasAutoreloadWithoutCard(state) {
  if (state.user && state.user.user && state.user.user.autoReloadCardInfo && state.user.user.autoReloadCardInfo.maskedCardNo) {
    let maskedCardNo = state.user.user.autoReloadCardInfo.maskedCardNo
    let masterpassCardsList = state.user.masterpassCardsList
    let autoreloadCard = masterpassCardsList.find(c => c.Value1 == maskedCardNo)
    if (autoreloadCard) {
      return false // autoreload talimatı var ve kart varsa aynı false döner
    }
    return true // autoreload talimatı var ama masterpass card listesinde bu kart yoksa true döner ve talimat silinir..
  }
  return false // autoreload talimatı yoksa false döner
}

export function getActivityStatus(state, orderId) {
  if (state.user && state.user && state.user.activities) {
    let act = state.user.activities.find(a => a.orderId == orderId)
    if (act) {
      return act.status //completed || failed || pending
    }
  }
  return ""
}

//!cms helpers

export function getCmsGreetingMessage(state) {
  let local = getLocale(state)
  if (state.catalog.settings) {
    let content = state.catalog.settings.find(s => s.contentid == "GreetingMessages")
    if (content && content.periods) {
      let foundPeriod = 0
      let periods = content.periods.map(p => p.period)
      var format = 'hh:mm'
      var time = moment()
      periods.forEach((p, i) => {
        let beforeTime = moment(p.split("-")[0], format)
        let afterTime = moment(p.split("-")[1], format)
        if (time.isBetween(beforeTime, afterTime)) {
          foundPeriod = i
        }
      })
      if (content.periods[foundPeriod].message && content.periods[foundPeriod].message[local]) {
        return {
          messageText: content.periods[foundPeriod].message[local],
          messageImageUrl: content.periods[foundPeriod].imageUrl
        }
      }
      return {
        messageText: "",
        messageImageUrl: ""
      }
    }
  }
  return {
    messageText: "",
    messageImageUrl: ""
  }
}

export function getCmsRewards(state) {
  if (state.catalog.settings) {
    let rewards = state.catalog.settings.find(s => s.contentid == "Rewards")
    if (rewards && rewards.cards) {
      return rewards.cards.filter(c => c.isActive == true)
    }
  }
  return []
}

export function getCmsCardNonLogin(state) {
  if (state.catalog.settings) {
    let cardNonLogin = state.catalog.settings.find(s => s.contentid == "CardNonLogin")
    if (cardNonLogin && cardNonLogin.cards) {
      return cardNonLogin.cards.find(c => c.isActive == true)
    }
  }
  return null
}

export function getCmsCardPayCardBanner(state) {
  if (state.catalog.settings) {
    let cardPayCardBanner = state.catalog.settings.find(s => s.contentid == "CardPayCardBanner")
    if (cardPayCardBanner && cardPayCardBanner.cards) {
      return cardPayCardBanner.cards.find(c => c.isActive == true)
    }
  }
  return null
}

export function getCmsCardBanner(state) {
  if (state.catalog.settings) {
    let cardBanner = state.catalog.settings.find(s => s.contentid == "CardBanner")
    if (cardBanner && cardBanner.cards) {
      return cardBanner.cards.find(c => c.isActive == true)
    }
  }
  return null
}

export function getCmsGiftCard(state) {
  if (state.catalog.settings) {
    let giftCard = state.catalog.settings.find(s => s.contentid == "GiftCard")
    if (giftCard && giftCard.cards) {
      return giftCard.cards.filter(c => {
        return (c.isActive == true && c.publish && c.publish.web == true)
      })
      //return giftCard.cards.filter(c => (c.isActive == true && c.publish &&c.publish.web==true))
    }
  }
  return null
}

export function getCmsGiftCardInformationUrl(state) {
  let locale = getLocale(state)
  if (state.catalog.settings) {
    let giftCard = state.catalog.settings.find(s => s.contentid == "GiftCard")
    if (giftCard && giftCard.cards) {
      let card = giftCard.cards.find(c => c.description && c.description.tr == "GiftCardInformation")
      if (card) {
        return card.imageUrl[locale + "Url"]
      }
    }
  }
  return "#"
}

export function getHelpLink(state) {
  let locale = getLocale(state)
  let url = publicRuntimeConfig.SB_CONTENT_API + `/static/docs/${locale}/help.html`
  return url
}

export function getTermsOfUse(state) {
  let locale = getLocale(state)
  let url = publicRuntimeConfig.SB_CONTENT_API + `/static/docs/${locale}/terms.html`
  return url
}

export function getPersonalDisc(state) {
  let locale = getLocale(state)
  let url = publicRuntimeConfig.SB_CONTENT_API + `/static/docs/${locale}/personaldisc.html`
  return url
}

export function getPersonalContact(state) {
  let locale = getLocale(state)
  let url = publicRuntimeConfig.SB_CONTENT_API + `/static/docs/${locale}/personalcontact.html`
  return url
}

export function getPersonalConsentForm(state) {
  let locale = getLocale(state)
  let url = publicRuntimeConfig.SB_CONTENT_API + `/static/docs/${locale}/personalconsentform.html`
  return url
}

export function getCrmContent(state, contentType) {
  if (state.catalog.content) {
    return state.catalog.content[contentType]
  }
  return []
}

export function getSegment(state) {
  if (state.user && state.user.user && state.user.user) {
    if (!state.user.user.segments || state.user.user.segments.length == 0) {
      return "RegisteredUsers"
    } else {
      let segments = [...state.user.user.segments]
      segments.push("RegisteredUsers")
      return encodeURI(segments.join("|"))
    }
  }
  return "anonymous"
}

//anonim-login kullanicilarda keyler farkli onları almak için...
export function getBannerKey(state, key) {
  if (state.user && state.user.user) {
    return key
  } else if (key == "Title") return "TitleDefault"
  else if (key == "ShortBody") return "BodyShortDefault"
  else if (key == "Body") return "BodyDefault"
  else return key
}

export function replaceTextWithUserData(state, str) {
  if (str) {
    if (state.user && state.user.user) {
      let firstName = state.user.user.firstName
      let preferredName = state.user.user.preferredName
      let lastName = state.user.user.lastName
      let starcount = state.user.user.stars && state.user.user.stars.starCount
      let balance = (state.user.user.cards && state.user.user.cards[0]) ? state.user.user.cards[0].balance : "@balance"
      let starsRequiredForReward = state.user.user.stars && state.user.user.stars.starsRequiredForReward
      let rewardCount = state.user.user.stars && state.user.user.stars.rewardCount
      let couponCode = state.user.user.couponCode

      return str.replace("@name", firstName)
        .replace("@preferredName", preferredName)
        .replace("@surname", lastName)
        .replace("@starcount", starcount)
        .replace("@balance", balance)
        .replace("@starsRequiredForReward", starsRequiredForReward)
        .replace("@rewardCount", rewardCount)
        .replace("@couponCode", couponCode)
    }
    return str.replace("@name", "")
      .replace("@preferredName", "")
      .replace("@surname", "")
      .replace("@starcount", "")
      .replace("@balance", "")
      .replace("@starsRequiredForReward", "")
      .replace("@rewardCount", "")
      .replace("@couponCode", "")
  }
  return ""
}

export function getInboxMessages(state) {
  if (state.catalog.content && state.catalog.content.inboxMessage) {
    return state.catalog.content.inboxMessage
  }
  return []
}

export function getInboxOffer(state) {
  if (state.catalog.content && state.catalog.content.inboxOffer) {
    return state.catalog.content.inboxOffer
  }
  return []
}

export function getPastOffer(state) {
  if (state.catalog.content && state.catalog.content.pastOffer) {
    return state.catalog.content.pastOffer
  }
  return []
}

export function getSoonExpiringStars(state) {
  if (state.user && state.user.starsInfo && state.user.starsInfo.soonExpiringStars) {
    return state.user.starsInfo.soonExpiringStars
  }
  return []
}

export function getRecentlyExpiredStars(state) {
  if (state.user && state.user.starsInfo && state.user.starsInfo.recentlyExpiredStars) {
    return state.user.starsInfo.recentlyExpiredStars
  }
  return []
}

export function checkFormVersions(formsLatest, currentUser) {
  if (currentUser && !currentUser.consents) {
    return {
      terms: false,
      personalconsentform: false,
      personaldisc: false,
    }
  } else if (currentUser && currentUser.consents && formsLatest) {
    return {
      terms: formsLatest.find(f => f.docType == "terms")["version"] == currentUser.consents.terms,
      personalconsentform: formsLatest.find(f => f.docType == "personalconsentform")["version"] == currentUser.consents.personalconsentform,
      personaldisc: formsLatest.find(f => f.docType == "personaldisc")["version"] == currentUser.consents.personaldisc
    }
  }
  return {
    terms: false,
    personalconsentform: false,
    personaldisc: false,

  }
}

export function needFormUpdate(state) {
  let returnVal = false
  if (!isAnonymUser(state) && state.catalog && state.catalog.checkFormVersions) {
    Object.keys(state.catalog.checkFormVersions).forEach(k => {
      if (!state.catalog.checkFormVersions[k]) {
        returnVal = true
      }
    })
  }
  return returnVal
}
/////////////////////////

export function getDayNameFromDate(date) {
  //date => 27/05/2020
  let dayNames = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
  var dateFormatted = date.replace('/', '-')
  dateFormatted = dateFormatted.replace('/', '-')
  var date = moment(dateFormatted, "DDMMYYYY")
  var dow = date.day()
  return dayNames[dow]
}